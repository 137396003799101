import React from 'react';
import Partnerid from './Partnerid';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import SimpleMap from './Map';
import { Link } from 'react-router-dom';
import esileht from '../src/images/esileht.JPG';

function Esileht() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Container fluid>
                <div className='front-page'>
                    <Row className='w-100 esileht'><img className='w-100' src={esileht} alt='esileht'></img></Row>
                    <Row className='welcome m-auto justify-content-center'>
                        <Row className='p-0 w-100'>
                            <Col className='d-flex justify-content-center'><h1 className='drop1'>RIPPLAGEDE</h1></Col>
                        </Row>
                        <Row className='p-0 w-100'>
                            <Col className='d-flex justify-content-center'>
                                <h1 className='drop2'>MÜÜK</h1>
                                <h1 className='drop3'>&nbsp;JA&nbsp;</h1>
                                <h1 className='drop4'>PAIGALDUS</h1>
                            </Col>
                        </Row>
                        <Row className='p-0 w-100'>
                            <Col className='text-center w-100'>
                            <p className='w-100'>Lestromi meeskond omab pikaaegseid kogemusi ripplagede müügis ja paigalduses.
                            Meie eesmärk on tagada täpne ja kvaliteetne töö, mis vastab Teie vajadustele ja ootustele.
                            </p>
                            </Col>
                        </Row>
                        <Row className='p-0 w-100'>
                            <Col className='row justify-content-center' >
                                <Button className='welcome-button display-control' variant="outline-dark">
                                <Link className='nav-link link-override' to={{pathname: '/meist', search: '?section=meie-lugu'}}>Loe meist lähemalt</Link>
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </div>
        </Container>
        <Container className='display-control' fluid>
            <Nav className="justify-content-between flex-wrap">
                <Nav.Item><Link className='nav-link' to={{pathname: '/galerii', search: '?section=villalaed'}}>VILLALAED</Link></Nav.Item>
                <Nav.Item><Link className='nav-link' to={{pathname: '/galerii', search: '?section=metall-laed'}}>METALL-LAED</Link></Nav.Item>
                <Nav.Item><Link className='nav-link' to={{pathname: '/galerii', search: '?section=kipslaed'}}>KIPSLAED</Link></Nav.Item>
                <Nav.Item><Link className='nav-link' to={{pathname: '/galerii', search: '?section=puitlaed'}}>PUITLAED</Link></Nav.Item>
                <Nav.Item><Link className='nav-link' to={{pathname: '/galerii', search: '?section=akustilised'}}>AKUSTILISED LAHENDUSED</Link></Nav.Item>
            </Nav>
        </Container>
        <Partnerid />
        <Container fluid>
        <Row>
            <Col>
                <Row><h2 className='p-4 text-center'>VÕTA MEIEGA ÜHENDUST</h2></Row>
                <Row><p className='px-4 text-center'>Võta meiega ühendust läbi infokanalite või astu läbi meie Tartu kontorist aadressil Turu 34B, kus on võimalik erinevaid lagesid oma silmaga vaadata ja käega katsuda.</p></Row>
            </Col>
            <Row className='py-4 d-flex px-0 mx-0 border-top border-dark' style={{height: '500px'}}><SimpleMap /></Row>
        </Row>
        <Row className='border-top border-dark adjust-flex'>
            <Col className='border-control'>
                <Row><h2 className='p-4 text-center'>ASTU LÄBI MEIE KONTORIST</h2></Row>
                <Row><p className='text-center'>Aadress: Turu 34B, Tartu</p></Row>
                <Row><p className='text-center'>Registrikood: 12531240</p></Row>
                <Row><p className='text-center'>KMKR nr: EE101665933</p></Row>
                <Row><h4 className='p-4 text-center'>LESTROM OÜ</h4></Row>
            </Col>
            <Col className='btn-align'>
                <Row className='py-4 justify-content-center'><Button variant="outline-dark" href='tel:+3725247705'><LocalPhoneSharpIcon></LocalPhoneSharpIcon> Helista +372 524 7705</Button></Row>
                <Row className='pb-4 justify-content-center'><Button variant="outline-dark" href='mailto:m.lestrom@gmail.com'><EmailSharpIcon></EmailSharpIcon> Kirjuta m.lestrom@gmail.com</Button></Row>
            </Col>
        </Row>
        </Container>
        </div>
    );
}

export default Esileht;