import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Esileht from './Esileht';
import Meist from './Meist/Meist';
import Galerii from './Galerii/Galerii';
import Kontakt from './Kontakt/Kontakt';
import { Routes, Route, useLocation } from 'react-router-dom';

function App() {
    const location = useLocation();
    
    useEffect(() => {
        switch (true) {
            case location.pathname.startsWith('/meist'):
                document.body.style.backgroundColor = '#3F497F'; // set background to blue when Meist component is rendered
                document.body.style.color = 'white';
                document.querySelector('.navbar-brand').style.color = 'white';
                document.querySelector('.navbar-toggler').classList.add('navbar-dark');
                document.querySelectorAll('.divider').forEach(divider => {divider.style.backgroundColor = 'white';});
                document.querySelectorAll('.nav-link').forEach(navLink => {navLink.style.color = 'white';});
                document.querySelectorAll('.footer-col').forEach(footerCol => {footerCol.style.borderColor = 'white';});
                break;
            case location.pathname.startsWith('/kontakt'):
                document.body.style.backgroundColor = '#3C6255'; // set background to green when Kontakt component is rendered
                document.body.style.color = 'white';
                document.querySelector('.navbar-brand').style.color = 'white';
                document.querySelector('.navbar-toggler').classList.add('navbar-dark');
                document.querySelectorAll('.divider').forEach(divider => {divider.style.backgroundColor = 'white';});
                document.querySelectorAll('.nav-link').forEach(navLink => {navLink.style.color = 'white';});
                document.querySelectorAll('.footer-col').forEach(footerCol => {footerCol.style.borderColor = 'white';});
                break;
            default:
                document.body.style.backgroundColor = '#F8F9FA'; // set default background color for all other components
                document.body.style.color = 'black';
                document.querySelector('.navbar-brand').style.color = 'black';
                document.querySelector('.navbar-toggler').classList.add('navbar-light');
                document.querySelectorAll('.divider').forEach(divider => {divider.style.backgroundColor = 'black';});
                document.querySelectorAll('.nav-link').forEach(navLink => {navLink.style.color = 'black';});
                document.querySelectorAll('.footer-col').forEach(footerCol => {footerCol.style.borderColor = 'black';});
                break;
        }
    }, [location]);

    return (
        <div>
            <Header />
            <Routes>
                <Route exact path='/' element={<Esileht />} />
                <Route path="/meist/*" element={<Meist />} />
                <Route path="/galerii/*" element={<Galerii />} />
                <Route path="/kontakt/*" element={<Kontakt />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
