import React, { useCallback, useEffect, useRef } from 'react';

function Divider(props) {
  const dividersRef = useRef([]);

  const handleScroll = useCallback(() => {
    dividersRef.current.forEach(divider => {
      if (divider) {
        const bounding = divider.getBoundingClientRect();
        const { top, bottom } = bounding;

        if (top >= 0 && bottom <= window.innerHeight) {
          divider.classList.add('animated');
        } else {
          divider.classList.remove('animated');
        }
      }
    });
  }, []);

  useEffect(() => {
    dividersRef.current = Array.from(document.querySelectorAll('.divider'));

    // Trigger the animation on page load if any dividers are already in view
    handleScroll();

    // Trigger the animation on scroll
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Remove the scroll event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const classNames = ['divider'];
  if (props.className) {
    classNames.push(props.className);
  }

  return (
      <div className={classNames.join(' ')} ref={el => dividersRef.current.push(el)}></div>
  );
}

export default Divider;




