import React from "react";
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@mui/icons-material/Room';

const LocationPin = ({ text }) => (
  <div className='pin'>
    <RoomIcon style={{ fontSize: '2.5rem' }}></RoomIcon>
    <p className="pin-text" style={{ width: '100px', color: 'black' }}>{text}</p>
  </div>
)

export default function SimpleMap(){
  const API_KEY = process.env.REACT_APP_GOOGLE_API;
  const defaultProps = {
    center: {
      lat: 58.355887,
      lng: 26.742171
    },
    zoom: 17
  };

  return (
    // Important! Always set the container height explicitly
    <div className="map contact-map" style={{ height: 'auto', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <LocationPin
          lat={58.355887}
          lng={26.742171}
          text="Turu 34B"
        />
      </GoogleMapReact>
    </div>
  );
}