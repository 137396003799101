import React, {useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import MeieLugu from './MeieLugu';
import Toopakkumised from './Toopakkumised';
import meist from '../images/meist.png';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';

function Meist() {
    const location = useLocation();
    const meieluguRef = useRef(null);
    const toopakkumisedRef = useRef(null);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const section = queryParams.get('section');
        switch (section) {
            case 'meie-lugu':
                meieluguRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'toopakkumised':
                toopakkumisedRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                window.scrollTo(0, 0);
                break;
        }
    }, [location]);
    return (
        <>
            <Container className='front-page d-flex flex-column' fluid>
                <Col><img className='meist w-100 pb-4' src={meist} alt='meist'></img></Col>
                <Col className='d-flex flex-fill border-top border-white'>
                    <div className='h-100 d-flex align-items-center about-line '><h2 className='display-1 px-2'>10 AASTAT</h2></div>
                    <div className='h-100 d-flex align-items-center'><h2 className='opacity-settings display-1 w-100 text-white px-2'>KOGEMUST</h2></div>
                </Col>
            </Container>
            <section ref={meieluguRef}><MeieLugu /></section>
            <section ref={toopakkumisedRef}><Toopakkumised /></section>
        </>
    );
}

export default Meist;