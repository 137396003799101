import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Divider from '../Divider';
import TartuKontor from './TartuKontor';
import kontakt from '../images/kontakt.jpg';
import VotaUhendust from './VotaUhendust';

function Kontakt() {
    window.scrollTo(0, 0);
    return (
        <>
            <Container className='front-page' fluid>
                <Col><img className='kontakt w-100 pb-4' src={kontakt} alt='kontakt'></img></Col>
                <Col className='d-flex border-top border-white'>
                    <div className='about-line w-100 contact-text-align'><h2 className='display-1 text-center px-2'>VÕTA</h2></div>
                    <div className='about-line w-100 contact-text-align'><h2 className='display-1 text-center text-white opacity-settings px-2'>MEIEGA</h2></div>
                    <div className='w-100 contact-text-align'><h2 className='display-1 text-center px-2'>ÜHENDUST</h2></div>
                </Col>
            </Container>
            <Container fluid>
                <Divider />
                <TartuKontor />
            </Container>
            <Container fluid>
                <Divider />
                <VotaUhendust />
            </Container>
        </>
    );
}

export default Kontakt;