import React, { useState } from 'react';
import Divider from '../Divider';
import { Container, Col, Row } from 'react-bootstrap';
import ecophon from '../logos/ecophon.png';
import ehitustrust from '../logos/ehitustrust.png';
import embach from '../logos/embach.png';
import espak from '../logos/espak.png';
import mapri from '../logos/mapri.png';
import mapri2 from '../logos/mapri2.png';
import parmet from '../logos/parmet.png';
import probuilding from '../logos/probuilding.png';
import Nav from 'react-bootstrap/Nav';

function MeieLugu() {
    const [hover, setHover] = useState(false);

    function handleMouseOver() {
        setHover(true);
    }
    function handleMouseOut() {
        setHover(false);
    }
    const imgMapri = hover ? mapri2 : mapri;
    return (
        <Container fluid>
            <Divider />
            <Row><h2 className='py-4 text-center'>MEIE LUGU</h2></Row>
            <Row>
                <Col>
                    <Row className='h-100'>
                        <p className='lead'>Lestrom OÜ on pühendunud ripplagede müügi ja paigaldusega tegelev ettevõte, mille tegevus on keskendunud kvaliteedi, täpsuse ja professionaalsuse tagamisele.
                        Meie ettevõtte asutamisest alates 2013. aastal oleme pidevalt arenenud ja täiustanud oma oskusi ning kogemusi, kasvades Lõuna-Eesti suurimaks ripplagede paigaldajaks.
                        Meie meeskond koosneb kogenud ja professionaalsetest ripplagede spetsialistidest, kes on võimelised pakkuma tipptasemel lahendusi igat tüüpi ehitusprojektidele.
                        </p> 
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <p className='lead'>Teeme tihedat koostööd kõigi Eesti juhtivate ehitusettevõtetega ning oleme saavutanud maine usaldusväärse partnerina, kes suudab täita kõrgemaid kvaliteedinõudeid ja tähtaegu.
                        Meie ettevõte on spetsialiseerunud erinevatele ripplagede tüüpidele, sealhulgas metallist, puidust ja kipsplaadist ripplagedele.
                        </p>
                    </Row>
                    
                </Col>
            </Row>
            <Row className='meist-img'>
                <Col className='d-flex flex-wrap justify-content-between'>
                    <Nav.Link href='https://www.ecophon.com/et/'><img src={ecophon} alt='ecophon-logo'></img></Nav.Link>
                    <Nav.Link href='https://www.ehitustrust.ee/'><img src={ehitustrust} alt='ehitustrust-logo'></img></Nav.Link>
                    <Nav.Link href='https://embach.ee/'><img src={embach} alt='embach-logo'></img></Nav.Link>
                    <Nav.Link href='https://espak.ee/'><img src={espak} alt='espak-logo'></img></Nav.Link>
                    <Nav.Link href='https://mapri.eu/'><img src={imgMapri} alt='mapri-logo' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}></img></Nav.Link>
                    <Nav.Link href='https://parmet.ee/'><img src={parmet} alt='parmet-logo'></img></Nav.Link>
                    <Nav.Link href='https://p-b.ee/'><img src={probuilding} alt='probuilding-logo'></img></Nav.Link>
                </Col>
                
            </Row>
        </Container>
    );
}

export default MeieLugu;