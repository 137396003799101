import React, { useState } from 'react';
import akust1 from '../images/akust1.jpg';
import akust2 from '../images/akust2.jpg';
import akust3 from '../images/akust3.jpg';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function Akustilised() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [akust1, akust2, akust3];

    const handleNextClick = () => {
        if (currentIndex === images.length - 1) {
            return;
        }
        setCurrentIndex(currentIndex + 1);
    };
    const handlePreviousClick = () => {
        if (currentIndex === 0) {
            return;
        }
        setCurrentIndex(currentIndex - 1);
    };

    return (
        <Container className='d-flex flex-row overflow-hidden gallery' fluid>
            <Col className='adjust-width-1 description'>
                <Row><h2 className='py-4'>AKUSTILISED LAHENDUSED</h2></Row>
                <Row className='flex-fill'><p>Akustilised villaplaadid lagedele ja seintele.</p></Row>
                <Row><p>{currentIndex + 1} / {images.length}</p></Row>
                <Row>
                    <Col className='pb-4'>
                        <Button variant='outline-dark' onClick={handlePreviousClick} disabled={currentIndex === 0}>
                            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                        </Button>
                    </Col>
                    <Col className='pb-4'>
                        <Button variant='outline-dark' onClick={handleNextClick} disabled={currentIndex === images.length - 1}>
                            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col className='adjust-width-2'>
                <div className='gallery-container'>
                    {images.map((image, index) => (
                        <img
                            className='p-4'
                            key={index}
                            src={image}
                            alt={`akustiline lagi${index + 1}`}
                            style={{
                                transform: `translateX(-${currentIndex === 2 ? `${currentIndex * 100}%` : `${currentIndex * 100}%`})`,
                                transition: 'transform 0.5s ease-in-out',
                                width: '700px'
                            }}
                        />
                    ))}
                </div>
            </Col>
        </Container>
    );
}
export default Akustilised;