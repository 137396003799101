import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';

function VotaUhendust() {
    return (
        <Container fluid>
            <Col className='d-flex justify-content-between adjust-flex'>
                <Row className='adjust-width-3 py-4 description-2 adjust-margin'>
                    <h2 className='text-center pb-4'>VÕTA ÜHENDUST</h2>
                    <p className='px-4 flex-fill text-center'>
                        Meie kogenud müügimeeskond aitab teil valida õige ripplae vastavalt teie vajadustele ja eelarvele ning meie kvalifitseeritud paigaldajad tagavad, 
                        et teie uus ripplagi paigaldatakse korrektselt ja professionaalselt.
                    </p>
                </Row>
                <Row className='adjust-width-4 adjust-margin justify-content-end'>
                    <Row className='d-flex justify-content-center align-items-end py-4 px-0'>
                        <Button variant='outline-light' href='tel:+3725247705'>
                            <p className='nav-link btn-kontakt'><LocalPhoneSharpIcon /> Helista +372 524 7705</p>
                        </Button>
                    </Row>
                    <Row className='d-flex justify-content-center py-4 px-0'>
                        <Button variant='outline-light' href='mailto:m.lestrom@gmail.com'>
                            <p className='nav-link btn-kontakt'><EmailSharpIcon /> Kirjuta m.lestrom@gmail.com</p>
                        </Button>
                    </Row>
                </Row>
            </Col>
        </Container>
    );
}

export default VotaUhendust;