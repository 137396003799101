import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

function Header() {

  return (
    <Navbar expand="md">
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Brand href="/">LESTROM OÜ</Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="col justify-content-between flex-wrap" activeKey="/">
            <Nav.Item>
              <Link className="nav-link" to='/'>ESILEHT</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="nav-link" to='/meist'>MEIST</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="nav-link" to='/galerii'>GALERII</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="nav-link" to='/kontakt'>KONTAKT</Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
