import React, {useEffect, useRef } from 'react';
import Divider from '../Divider';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Villalaed from './Villalaed';
import MetallLaed from './MetallLaed';
import Kipslaed from './Kipslaed';
import Puitlaed from './Puitlaed';
import Akustilised from './Akustilised';
import galerii from '../images/galerii.jpg';
import { useLocation } from 'react-router-dom';


function Galerii() {
    const location = useLocation();
    const villalaedRef = useRef(null);
    const metalllaedRef = useRef(null);
    const kipslaedRef = useRef(null);
    const puitlaedRef = useRef(null);
    const akustilisedRef = useRef(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const section = queryParams.get('section');

        // Scroll to the appropriate section based on the value of the "section" parameter
        switch (section) {
            case 'villalaed':
                villalaedRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'metall-laed':
                metalllaedRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'kipslaed':
                kipslaedRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'puitlaed':
                puitlaedRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'akustilised':
                akustilisedRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                window.scrollTo(0, 0);
                break;
        }
    }, [location]);

    return (
        <>
        <Container className='front-page d-flex flex-column' fluid>
            <Col className='d-flex flex-fill border-bottom border-dark'>
                <div className='h-100 d-flex align-items-end gallery-line'><h2 className='display-1 px-2'>EKSPERDID</h2></div>
                <div className='h-100 w-100 d-flex align-items-end flex-nowrap'><h2 className='opacity-settings display-1 w-100 text-muted px-2'>RIPPLAGEDE PAIGALDUSES</h2></div>
            </Col>
            <Col><img className='d-flex galerii-img py-4 w-100' src={galerii} alt='galerii'></img></Col>
        </Container>
        <Container fluid>
            <Divider />
            <section ref={villalaedRef}><Villalaed /></section>
        </Container>
        <Container fluid>
            <Divider />
            <section ref={metalllaedRef}><MetallLaed/></section>
        </Container>
        <Container fluid>
            <Divider />
            <section ref={kipslaedRef}><Kipslaed /></section>
        </Container>
        <Container fluid>
            <Divider />
            <section ref={puitlaedRef}><Puitlaed /></section>
        </Container>
        <Container fluid>
            <Divider />
            <section ref={akustilisedRef}><Akustilised /></section>
        </Container>
        </>
    );
}

export default Galerii;