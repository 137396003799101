import React, {useState} from 'react';
import Divider from '../Divider';
import {Form, Container, Col, Row, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

function Toopakkumised() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;


    function handleSubmit(event) {
        event.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, event.target, PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });   
    };

    return (
        <Container fluid>
        <Divider />
            <Row><h2 className='py-4 text-center'>TÖÖPAKKUMISED</h2></Row>
            <Row className='d-flex py-0 adjust-flex'>
                <Col>
                    <Row><h3>Ripplagede paigaldaja</h3></Row>
                    <Row>
                        <p className='text-start'>Meie kollektiivi on alati oodatud professionaalsed ja kogenud ripplae paigaldajad, kes on eelnevalt omandanud väärtusliku ehituskogemuse ning omavad oskusi ja teadmisi, et tagada ripplagede täpne ja tõhus paigaldamine. 
                        Meie meeskond hindab kõrgelt kvaliteeti, täpsust ning professionaalset suhtumist ning usub, et meie klientide rahulolu sõltub suuresti meie meeskonna kvalifikatsioonist ja oskustest. 
                        Seega kutsume kõiki oma ala professionaale liituma meie meeskonnaga ning aitama kaasa meie ettevõtte eesmärkide saavutamisele.
                        </p>
                    </Row>
                    <Row>
                        <h5>Mida me Sinult ootame:</h5>
                        <ul className='px-4'>
                            <li>Täpne ja vastutustundlik</li>
                            <li>Varasem ehitusalane kogemus</li>
                            <li>Valmidus Eestisisesteks lähetusteks</li>
                        </ul>
                    </Row>
                    <Row>
                        <h5>Mida meie pakume:</h5>
                        <ul className='px-4'>
                            <li>Konkurentsivõimelist töötasu</li>
                            <li>Mitmekülgset tööd</li>
                            <li>Kvaliteetsed tööriided ja töövahendid</li>
                        </ul>
                    </Row>
                    <Row>
                        <h5>Tööaeg:</h5>
                        <ul className='px-4'>
                            <li>Täistööaeg, E-R 8-17</li>
                            <li>vajadusel võimalik teisiti kokku leppida</li>
                        </ul>
                    </Row>
                    <Row>
                        <h5>Asukoht:</h5>
                        <ul className='px-4'>
                            <li>Tartu ja Lõuna-Eesti</li>
                        </ul>
                    </Row>
                </Col>
                <Col>
                    <Row className='d-flex flex-fill text-center'><p className='lead m-0'>Kandideerimiseks saada meile kiri ja me võtame Sinuga peatselt ühendust.</p></Row>
                    <Row className='d-flex flex-column justify-content-center align-items-center'>
                        <Form className='w-100' onSubmit={handleSubmit}>
                            <Form.Group className='mb-3' controlId='firstName'>
                                <Form.Label>Eesnimi:</Form.Label>
                                <Form.Control type='text' placeholder='' name='firstName' value={firstName} onChange={event => setFirstName(event.target.value)} required />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='lastName'>
                                <Form.Label>Perekonnanimi:</Form.Label>
                                <Form.Control type='text' placeholder='' name='lastName' value={lastName} onChange={event => setLastName(event.target.value)} required />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='email'>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type='email' placeholder='' name='email' value={email} onChange={event => setEmail(event.target.value)} required />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='phone'>
                                <Form.Label>Telefon:</Form.Label>
                                <Form.Control type='tel' placeholder='' name='phone' value={phone} onChange={event => setPhone(event.target.value)} required />
                            </Form.Group>
                            <Form.Group controlId='message'>
                                <Form.Label>Räägi meile endast: </Form.Label>
                                <Form.Control as='textarea' rows={4} placeholder='' name='message' value={message} onChange={event => setMessage(event.target.value)} />
                            </Form.Group>
                            <Form.Group className='d-flex justify-content-center py-4 '>
                                <Button variant='outline-light' type='submit' value='submit'><p className='nav-link btn-meist fw-bold'>Saada</p></Button>
                            </Form.Group>
                        </Form>
                    </Row>
                </Col>    
            </Row>
        </Container>
    );
}

export default Toopakkumised;