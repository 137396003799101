import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SimpleMap from '../Map';

function TartuKontor() {
    return (
        <Container className='d-flex flex-row contact' fluid>
            <Col className='adjust-width-1 py-4 description-2'>
                <Row>
                    <h2 className='pb-4 text-center'>TARTU KONTOR</h2>
                </Row>
                <Row>
                    <p>Aadress: Turu 34B, 50104 Tartu</p>
                    <p>Registrikood: 12531240</p>
                    <p>KMKR nr: EE101665933</p>
                </Row>
                <Row className='flex-fill text-center'>
                    <p className='px-4'>
                        Meie kontor on avatud kõigile, kes soovivad lähemalt tutvuda meie toodete ja teenustega.
                        Meie lai valik ripplagesid on saadaval erinevates materjalides, stiilides ja suurustes, et sobida teie kodu või äri ruumidega.
                    </p>
                </Row>   
                <Row><h5 className='m-0'>LESTROM OÜ</h5></Row>
            </Col>
            <Row className='adjust-width-2 py-4 adjust-margin'><div className='px-0'><SimpleMap /></div></Row>
        </Container>
    );
}

export default TartuKontor;