import React from 'react';
import Divider from './Divider';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FacebookIcon from '@mui/icons-material/Facebook';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

function Footer() {
    const year = new Date().getFullYear();
    return (
        <Container fluid>
            <Divider />
            <Row className='px-4'>
                <Col className='d-flex adjust-flex'>
                    <Col className='pt-4'>
                        <Link className='nav-link' to='/meist'><h5>Meist</h5></Link>
                        <Link className="nav-link" to={{pathname: '/meist', search: '?section=meie-lugu'}}>Meie lugu</Link>
                        <Link className='nav-link' to={{pathname: '/meist', search: '?section=toopakkumised'}}>Tööpakkumised</Link>
                    </Col>
                    <Col className='pt-4'>
                        <Link className='nav-link' to='/galerii'><h5>Galerii</h5></Link>
                        <Link className='nav-link' to={{pathname: '/galerii', search: '?section=villalaed'}}>Villalaed</Link>
                        <Link className='nav-link' to={{pathname: '/galerii', search: '?section=metall-laed'}}>Metall-laed</Link>
                        <Link className='nav-link' to={{pathname: '/galerii', search: '?section=kipslaed'}}>Kipslaed</Link>
                        <Link className='nav-link' to={{pathname: '/galerii', search: '?section=puitlaed'}}>Puitlaed</Link>
                        <Link className='nav-link' to={{pathname: '/galerii', search: '?section=akustilised'}}>Akustilised lahendused</Link>
                    </Col>
                </Col>
                <Col className='d-flex adjust-flex'>
                    <Col className='pt-4'>
                        <h5 style={{opacity: '0.6'}}>Sotsiaalmeedia</h5>
                        <Nav.Link href='https://www.facebook.com/moodullaed/'><FacebookIcon></FacebookIcon>Facebook</Nav.Link>
                    </Col>
                    <Col className='pt-4'>
                        <Link className='nav-link' to='/kontakt'><h5>Kontakt</h5></Link>
                        <p className='m-0' style={{opacity: '0.6'}}>Aadress: Turu 34B, Tartu</p>
                        <p className='m-0' style={{opacity: '0.6'}}>Tel: +372 524 7705</p>
                        <a className='nav-link m-0' style={{opacity: '0.6'}} href='mailto:m.lestrom@gmail.com'>Email: m.lestrom@gmail.com</a>
                    </Col>
                </Col>
            </Row>
            <Row className='py-2 col justify-content-center' style={{opacity: '0.6'}}>© Lestrom OÜ {year}</Row>
        </Container>
    );
}

export default Footer;