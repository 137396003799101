import React, { useState } from 'react';
import Divider from './Divider';
import Container from 'react-bootstrap/Container';
import ecophon from './logos/ecophon.png';
import ehitustrust from './logos/ehitustrust.png';
import embach from './logos/embach.png';
import espak from './logos/espak.png';
import mapri from './logos/mapri.png';
import mapri2 from './logos/mapri2.png'
import parmet from './logos/parmet.png';
import probuilding from './logos/probuilding.png';

function Partnerid() {
  const [hover, setHover] = useState(false);

    function handleMouseOver() {
        setHover(true);
    }
    function handleMouseOut() {
        setHover(false);
    }
    const imgMapri = hover ? mapri2 : mapri;
  return (
    <Container className='border-bottom border-dark' fluid>
        <Divider />
        <div className='py-4'><h2 className='text-center'>MEIE KOOSTÖÖPARTNERID</h2></div>
        <div className="partner-carousel-container">
            <div className="partner-carousel pb-4">
                <img src={ecophon} alt='ecophon-logo' />
                <img src={ehitustrust} alt='ehitustrust-logo' />
                <img src={embach} alt="logo3" />
                <img src={espak} alt="logo4" />
                <img src={imgMapri} alt="logo5" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}></img>
                <img src={parmet} alt="logo6" />
                <img src={probuilding} alt="logo7" />
                {/* Repeat the logos to create an infinite loop */}
                <img src={ecophon} alt='ecophon-logo' />
                <img src={ehitustrust} alt='ehitustrust-logo' />
                <img src={embach} alt="logo3" />
                <img src={espak} alt="logo4" />
                <img src={imgMapri} alt="logo5" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}></img>
                <img src={parmet} alt="logo6" />
                <img src={probuilding} alt="logo7" />
            </div>
        </div>
    </Container>
  );
}

export default Partnerid;
